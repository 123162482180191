export const polishTranslations = {
  login: {
    login: 'Logowanie',
    badCredentials: 'Błędne dane logowania',
    loginButton: 'Zaloguj się',
    sendCode: 'Wyślij kod',
    forgotPassword: 'Zapomniałem hasła',
    code: 'Kod'
  },
  labels: {
    email: 'Email',
    password: 'Hasło'
  },
  menu: {
    visits: 'Wizyty',
    patients: 'Pacjenci'
  },
  error: 'Błąd',
  success: 'Sukces',
  close: 'Zamknij',
  remove: 'Usuń',
  send: 'Wyślij',
  chooseUsg: 'Wybierz urządzenie do USG',
  forgotPassword: {
    haveCode: 'Mam już kod',
    sendButton: 'Wyślij',
    passwordResetRequestError: 'Błąd w wysłaniu prośby o reset hasła',
    passwordResetRequestSuccess:
      'Prośba o reset hasła wysłana. Sprawdź swoją skrzynkę mailową'
  },
  setPassword: {
    key: 'Klucz',
    repeatPassword: 'Powtórz hasło',
    sendNewPassword: 'Wyślij nowe hasło',
    backToLogin: 'Wróc do strony logowania',
    sePasswordSuccess: 'Poprawnie zresetowano hasło',
    emptyFieldError: 'Wypełnij wszystkie potrzebne pola',
    setPasswordError: 'Błąd resetu hasła'
  },
  visitsList: {
    epidStatus: 'Status epidemiologiczny',
    successMoveVisit: 'Przeniesiono wizytę',
    errorMoveVisit: 'Błąd w trakcie przenoszenia wizyty',
    insurance: 'Ubezpieczenie',
    insertedCheckup: 'Wprowadzono badanie',
    chooseVisitType: 'Wybierz typ wizyty',
    gabinet: 'Gabinetowa',
    televisit: 'Telewizyta',
    recipe: 'Recepturowa',
    updateUserError: 'Błąd aktualizacji użytkownika',
    successUpdateVisitType: 'Pomyślnie zaktualizowano typ wizyty',
    updateVisitTypeError: 'Błąd aktualizacji typu wizyty',
    makeVisit: 'Przeprowadź wizytę',
    planVisit: 'Zaplanuj wizytę',
    openPatientHistory: 'Otwórz historię pacjenta',
    editPatient: 'Edytuj pacjenta',
    seePatientContact: 'Zobacz kontakt do pacjenta',
    editVisitType: 'Edytuj typ wizyty',
    dateField: 'Godz./potw.',
    patientField: 'Pacjent, wiek',
    epidField: 'Epid, eWuś. Wyniki',
    actionField: 'Akcje'
  },
  patient: {
    patient: 'Pacjent',
    validationType: 'Typ walidacji',
    firstName: 'Imię',
    lastName: 'Nazwisko',
    sex: 'Płeć',
    phone: 'Numer telefonu',
    email: 'Email',
    city: 'Miejscowość',
    address: 'Adres',
    street: 'Ulica',
    houseNr: 'Nr domu',
    apartmentNr: 'Nr mieszkania',
    postalCode: 'Kod pocztowy',
    document: 'Nr dokumentu',
    authorizedPerson: 'Osoba upoważniona',
    interview: 'Wywiad wstępny',
    comment: 'Uwagi',
    save: 'Zapisz',
    saveAndStartVisit: 'Zapisz i rozpocznij wizytę',
    saveAndPlanVisit: 'Zapisz i zaplanuj wizytę'
  },
  patientList: {
    patient: 'Pacjent, wiek',
    previousVisit: 'Poprzednia wizyta',
    nextVisit: 'Następna wizyta',
    actions: 'Akcje',
    makeVisit: 'Przeprowadź wizytę',
    planVisit: 'Zaplanuj wizytę',
    openHistory: 'Otwórz historię pacjenta',
    editPatient: 'Edytuj pacjenta',
    showContact: 'Zobacz kontakt do pacjenta'
  },
  layout: {
    changeUsg: 'Zmień urządzenie USG',
    adminOrganization: 'Zarządzaj organizacją',
    adminDoctors: 'Zarządzaj lekarzami',
    logout: 'Wyloguj'
  },
  visit: {
    visit: 'Wizyta',
    captureMinimize: 'minimalizuj',
    captureSpeech: 'zapis rozmowy',
    captureSave: 'Zapisz',
    captureSummarize: 'Podsumowanie',
    captureSummarizing: 'Opracowuje',
    captureClear: 'Wyczyść',
    startVisit: 'Rozpocznij wizytę',
    addRecipe: 'Dodaj receptę',
    forms: 'Formularze',
    sendEmail: 'Wyślij email',
    saveVisit: 'Zapisz wizytę',
    visitDescription: 'Opis wizyty',
    requiredField: 'Pole jest obowiązkowe',
    diagnosis: 'Rozpoznanie',
    recommendations: 'Zalecenia',
    checkUps: 'Zlecone badanie',
    prescribedDrugs: 'Przepisane leki',
    drugs: 'Leki',
    quantity: 'Ilość',
    code: 'Kod',
    services: 'Usługi',
    suggestedDose: 'Sugerowana dawka',
    icd10: 'ICD10',
    controlVisitDate: 'Termin wizyty kontrolnej',
    manual: 'Ręczny',
    forMonth: 'Za miesiąc',
    forTwoMonths: 'Za 2 miesiące',
    forThreeMonths: 'Za 3 miesiące',
    forHalfYear: 'Za pół roku',
    forYear: 'Za rok',
    for: 'Za',
    weeks: 'tygodni',
    saveNextVisitDate: 'Zapisz termin następnej wizyty',
    notChoosenDate: 'Nie wybrano daty',
    visitDate: 'Termin wizyty',
    usg: 'USG',
    addUsgSuccess: 'USG zostało poprawnie dodane do wizyty',
    addUsgError: 'Błąd przy dodawaniu badania USG',
    usgDescription: 'Opis USG',
    referral: 'Skierowania',
    usgRefreshSuccess: 'Opis USG został odświeżony',
    usgRefreshError: 'Błąd przy odświeżeniu badania USG',
    initDataError: 'Błąd inicjalizacji danych',
    saveVisitError: 'Błąd zapisu wizyty',
    updateVisitError: 'Błąd aktualizacji wizyty',
    dataDescription: '',
    madeRecipeSuccess: 'Recepta została wystawiona',
    recipe: 'Recepta',
    madeRecipeError: 'Błąd zapisu recepty',
    getRecipeError: 'Błąd pobrania recept',
    getRecipeStatusError: 'Błąd pobrania statusu recepty',
    visitSummary: 'Podsumowanie wizyty',
    getPdfSummaryError: 'Błąd pobrania pdfu z podsumowaniem wizyty',
    getFormPdfError: 'Błąd pobrania pdfu z formularzem',
    setUsgDescriptionError: 'Błąd ustawienia opisu USG',
    getVisitDatesError: 'Błąd pobrania dat wizyt',
    sendEmailError: 'Błąd wysłania emaila',
    endVisitAlert: 'Wizyta została zakończona',
    changeVisitStatusError: 'Błąd zmiany statusu wizyty',
    checkUpDate: 'Data badania',
    chooseForm: 'Wybierz formularz',
    generate: 'Generuj',
    remove: 'Usuń',
    addReferral: 'Dodaj skierowanie',
    price: 'Cena',
    patientPastPrescription: 'Przeszła recepta pacjenta',
    addPrescription: 'Dodaj receptę',
    addPatient: 'Dodaj pacjenta',
    address: 'Dane adresowe',
    isInsuranced: 'Czy jest ubezpieczony',
    prescription365: 'Recepta 365 dni',
    rightsOptions: 'Dodatkowe uprawnienia',
    isSenior: 'Senior',
    validDateFrom: 'Data ważności od',
    validDateTo: 'Data ważności do',
    prescriptionType: 'Typ recepty',
    certPassword: 'Hasło certyfikatu',
    yearOld: 'roku życia',
    notReplace: 'Nie zamieniać',
    addDrugs: 'Dodaj leki',
    searchDrugs: 'Szukaj leku',
    onlyRefunded: 'R?',
    availableRefundations: 'Dostępne refundacje',
    addDrug: 'Dodaj lek',
    showAllDrugs: 'Pokaż wszytkie leki',
    addPrescriptionError: 'Problem z dodaniem recepty',
    visitsAndCheckups: 'Wizyty i badania',
    prescriptions: 'Recepty',
    interview: 'Wywiad wstępny',
    calendar: 'Kalendarz',
    epidInterview: 'Wywiad epidemiologiczny',
    positive: 'pozytywny',
    negative: 'negatywny',
    dosing: 'Dawkowanie',
    dose: 'Dawka',
    package: 'Paczka',
    refundation: 'Refundacja',
    setCheckUps: 'Zlecone badania',
    controlVisit: 'Wizyta kontrolna',
    total: 'Łącznie',
    showAll: 'Pokaż wszystkie',
    showToDo: 'Pokaż do realizacji',
    labs: 'Oddział szpitala (wymagany)',
    mainRecognition: 'Główne rozpoznania (wymagany)',
    additionalRecognition: 'Dodatkowe rozpoznania',
    referralError: 'Błąd w trakcie wysyłania skierowania',
    referralSuccess: 'Poprawnie wysłano skierowanie',
    title: 'Tytuł',
    cancel: 'Anuluj',
    description: 'Opis',
    referralDescription: 'Opis skierowania',
    removePrescriptionConfirmation: 'Czy napewno chcesz usunąć receptę?',
    removePrescriptionInfo:
      'Tej czynności nie można przywrócić. Naciśnij „usuń” jeśli chcesz usunąć',
    removePrescriptionSubmit: 'Ok: „Potwierdzenie usunięcia recepty”',
    removed: 'Usunięto',
    getIcdError: 'Błąd w pobraniu ICD',
    labCode: 'Kod pracowni diagnostycznej',
    procedure: 'Procedura',
    removeReferralConfirmation: 'Czy napewno chcesz usunąć skierowanie?',
    removeReferralInfo:
      'Tej czynności nie można przywrócić. Naciśnij „usuń” jeśli chcesz usunąć',
    referralTitleDescription: 'Szczegóły skierowania',
    getReferralError: 'Błąd w pobraniu opisu skierowania',
    referralCancelSuccess: 'Pomyślnie anulowano skierowanie',
    referralCancelError: 'Błąd w trakcie anulowania skierowania',
    referralCancelTitleDescription: 'Anulowanie skierowania',
    prescriptionDetailsTitle: 'Szczegóły recepty',
    procedures: 'Procedury',
    strongMedicamentAlert: 'Lek silnie działający',
    activeSubstances: 'Substancje aktywne',
    conditionDescriptionPlaceholder: 'Opis rozpoznania (wymagany)',
    prescriptionDoctor: 'Lekarz wystawiający',
    dateFrom: 'Data od',
    copyPrescription: 'Skopiuj receptę',
    copyPrescriptionError: 'Błąd w trakcie kopiowania recepty',
    showPatientRelated: 'Pokaż powiązane z pacjentem',
    showDoctorRelated: 'Pokaż powiązane z lekarzem',
    presInfoText: 'Proszę wybrać i dodać lek, aby zobaczyć go na recepcie',
    presTitle: 'Recepta ',
    addThisBtn: 'Dodaj',
    resetFormBtn: 'Zresetować formularz',
    issuePresBtn: 'Wydanie recepty',
    infoPopUpDetails: {
      infoPopupTitle: 'Informacje o medycynie',
      dose: 'Dawka',
      package: 'Pakiet',
      price: 'Cena',
      availableRefunds: 'Dostępne zwroty'
    },
    disablePresErrorMsg:
      'Nie można było dodać leku do już wystawionej recepty. Aby wystawić receptę na dodatkowe leki, kliknij przycisk Resetuj formularz'
  },
  organization: {
    doctors: 'Doktorzy',
    doctorSettings: 'Ustawienia doktora',
    save: 'Zapisz',
    workHours: 'Godziny przyjęć',
    addDoctor: 'Dodaj doktora',
    firstName: 'Imię',
    lastName: 'Nazwisko',
    actions: 'Akcje',
    getDoctorsError: 'Błąd przy pobraniu listy doktorów',
    organizationEdit: 'Zapisz zmiany',
    name: 'Nazwa',
    externalId: 'ID podmiotu OID ext',
    internalId: 'ID podmiotu OID Root',
    unitCode: 'Kod jednostki komórki',
    phone: 'Telefon',
    city: 'Miasto',
    street: 'Ulica',
    houseNr: 'Numer domu',
    apartmentNr: 'Numer lokalu',
    postalCode: 'Kod pocztowy',
    simc: 'Numer SIMC',
    rwpdlCode: 'Izba lekarska',
    tlsCert: 'Certyfikat TLS',
    tlsPlaceholder: 'Wybierz lub zrzuć certyfikat TLS',
    wssCert: 'Certyfikat WSS',
    wssPlaceholder: 'Wybierz lub zrzuć tu certyfikat WSS',
    saveCert: 'Wgraj certyfikat',
    getOrganizationError:
      'Wystąpił błąd pobrania organizacji, spróbuj ponownie',
    updateOrganizationSuccess: 'Zaktualizowano poprawnie organizację',
    updateOrganizationError:
      'Wystąpił błąd aktualizacji organizacji, spróbuj ponownie',
    updateCertsSuccess: 'Przesłano poprawnie certyfikaty',
    updateCertsError: 'Wystąpił błąd wysyłania certyfikatu, spróbuj ponownie',
    statisticNumber: 'Numer statystyczny',
    specialization: 'Specjalizacja',
    isAdmin: 'Uprawnienia administratorskie',
    cert: 'Certyfikat',
    visitTime: 'Czas wizyty',
    day: 'Dzień',
    phoneNumber: 'Numer telefonu',
    ewusLogin: 'Login EWUŚ',
    ewusPassword: 'Hasło EWUŚ',
    specializations: 'Specjalizacje',
    specializationsEng: 'Specjalizacje po angielsku',
    aboutMe: 'O mnie',
    awards: 'Nagrody i dyplomy',
    nfzDescription: 'Przyjmowanie na NFZ',
    treatedDiseases: 'Leczone choroby',
    experience: 'Doświadczenie',
    paymentMethods: 'Metody płatności',
    kindOfPatients: 'Rodzaje pacjentów',
    description: 'Opis',
    isHidden: 'Czy ukryta',
    price: 'Cena',
    dateFrom: 'Data od',
    dateTo: 'Data do',
    wwwName: 'Nazwa www',
    wwwIsHidden: 'Czy ukryte na www',
    services: 'Usługi',
    addDoctorService: 'Dodaj usługę lekarza',
    addServiceSuccess: 'Dodano usługę',
    addServiceError: 'Błąd przy dodawaniu usługi',
    updateServiceSuccess: 'Zaktualizowano usługę',
    updateServiceError: 'Błąd przy aktualizacji usługi',
    removeServiceSuccess: 'Usunięto usługę',
    removeServiceError: 'Błąd przy usunięciu usługi'
  }
}
